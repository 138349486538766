














































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {integer, minValue, required} from 'vuelidate/lib/validators';
import {namespace} from 'vuex-class';
import {shipArrivalStoreActions} from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import StepBaseComponent from '@/components/shipArrival/create/steps/StepBaseComponent.vue';

const ShipArrivalStore = namespace('shipArrival');

@Component({
  mixins: [validationMixin],
  validations: {
    validationObject: {
      carsImportVolume: {required, integer, minValue: minValue(0)},
      heavyImportVolume: {required, integer, minValue: minValue(0)},
      carsExportVolume: {required, integer, minValue: minValue(0)},
      heavyExportVolume: {required, integer, minValue: minValue(0)},
    }
  }
})
export default class Step3Component extends StepBaseComponent {

  @Prop({default: false})
  public isLoading!: boolean;

  /**
   * Mirror object to handle validation correclty (otherwise there can be a reference error)
   */
  private validationObject: {
    carsImportVolume: number | null;
    heavyImportVolume: number | null;
    carsExportVolume: number | null;
    heavyExportVolume: number | null;
  } = {
    carsImportVolume: null,
    heavyImportVolume: null,
    carsExportVolume: null,
    heavyExportVolume: null,
  }
  public isSubmitted: boolean = false;

  public heavyLimitReached = false;
  public carLimitReached = false;

  @ShipArrivalStore.Action(shipArrivalStoreActions.CREATE)
  public createShipArrival!: (payload: { shipArrival: ShipArrival }) => Promise<ShipArrival>;

  get isFormInvalid(): boolean {
    return this.isSubmitted && this.$v.$invalid;
  }

  @Watch('isFormInvalid', {immediate: true})
  private onInvalidChange() {
    this.$emit('is-invalid-change', this.isFormInvalid)
  }

  public mounted() {
    // Pass component to parent for validation
    this.$emit('mounted', this);

    // Use ship arrival data if available
    this.validationObject = {...this.validationObject, ...this.currentShipArrival};
  }

  public triggerValidationCalled(propertyPath: string) {
    this.triggerValidation(propertyPath);
    this.importExportChanged();
  }

  public importExportChanged() {
    if (this.validationObject.heavyExportVolume !== null && this.validationObject.heavyImportVolume !== null) {
      this.heavyLimitReached = (this.validationObject.heavyExportVolume +
          this.validationObject.heavyImportVolume) > 1600;
    }
    if (this.validationObject.carsExportVolume !== null && this.validationObject.carsImportVolume !== null) {
      this.carLimitReached = (this.validationObject.carsExportVolume +
          this.validationObject.carsImportVolume) > 8000;
    }
  }

  public beforeDestroy() {
    if (this.currentShipArrival) {
      // Reassign validation values
      this.setCurrentArrival(Object.assign(this.currentShipArrival, this.validationObject) as ShipArrival);
    }
  }

  public validate(): boolean {
    this.isSubmitted = true;
    this.$v.$touch();
    return !this.isFormInvalid;
  }

  public onFinish() {
    this.validate();

    if (!this.isFormInvalid) {
      // merge the validation object
      this.setCurrentArrival(Object.assign(this.currentShipArrival, this.validationObject) as ShipArrival);
      this.$emit('finish');
    }
  }

  public async previousStepClicked() {
    this.validate();
    if (!this.isFormInvalid) {
      this.$emit('previous-step');
    }
  }

}
